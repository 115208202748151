/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifCarousel.js                                  !
  !  Desc. : SissiF Front Dialog Painter Overview               !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 23/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {Close} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";

/*
--- Ouestadam products
*/
import {sissifDefDevice, sissifDefModal_e} from "./sissifDef";
import {sissifMainRefreshPage_f} from "./sissifMain";
import painterCardSissif from "./images/painterCardSissif.jpg";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = sissifDefModal_e.noModal;
    /*
    --- Refresh the main page
    */
    sissifMainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : LocPainterDesktopJSX                              !
! Description: JSX Painter overview desktop displaying        !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocPainterDesktopJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} maxWidth={"md"}>
                <div style={{
                    background: locColors.backgroundDialog,
                    color: locColors.foregroundDialog
                }}>
                    <DialogTitle>
                        <Grid container direction="row" justifyContent="center">
                            <Box sx={{ml: 4, mt: 0, mb: 2}}>
                                {locTrans.oaComTransGet_m("dialogPainter", "title")}
                            </Box>
                        </Grid>
                        <IconButton
                            aria-label="close"
                            onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                            sx={{position: 'absolute', right: 8, top: 8, color: locColors.foregroundDialog}}
                        >
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{pb: 0, mb: 0}}>
                        <Grid container direction="row" justifyContent="space-around" alignItems="flex-start"
                              spacing={1}>
                            <Grid item xs={2}>
                                <Avatar src={painterCardSissif}
                                        sx={{width: "107px", height: "107px", boxShadow: "1px 1px 4px black"}}
                                        alt={locTrans.oaComTransGet_m("dialogPainter", "title")}/>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text1")}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text2")}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text3")}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text4")}
                                </Typography>
                                <br/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{mt: 0, mb: 0}}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center"
                              sx={{ml: 2, mr: 2, mt: 0, mb: 0}}>
                            <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                                {locTrans.oaComTransGet_m("common", "return")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}

/*
+-------------------------------------------------------------+
! Routine : LocPainterMobileJSX                               !
! Description: JSX Painter overview mobile displaying         !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocPainterMobileJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} maxWidth={"md"}>
                <div style={{
                    background: locColors.backgroundDialog,
                    color: locColors.foregroundDialog
                }}>
                    <DialogTitle>
                        <Grid container direction="row" justifyContent="center">
                            <Box sx={{ml: 4, mt: 0}}>
                                {locTrans.oaComTransGet_m("dialogPainter", "title")}
                            </Box>
                        </Grid>
                        <IconButton
                            aria-label="close"
                            onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                            sx={{position: 'absolute', right: 8, top: 8, color: locColors.foregroundDialog}}
                        >
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{pb: 0, mb: 0}}>
                        <Grid container direction="column" justifyContent="space-evenly" alignItems="center"
                              spacing={1}>
                            <Grid item>
                                <Avatar src={painterCardSissif}
                                        sx={{width: "107px", height: "107px", boxShadow: "1px 1px 4px black", mb: 2}}
                                        alt={locTrans.oaComTransGet_m("dialogPainter", "title")}/>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text1")}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text2")}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text3")}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    {locTrans.oaComTransGet_m("dialogPainter", "text4")}
                                </Typography>
                                <br/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{mt: 0, mb: 0}}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center"
                              sx={{ml: 2, mr: 2, mt: 0, mb: 0}}>
                            <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                                {locTrans.oaComTransGet_m("common", "return")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : SissifPainterJSX                                  !
! Description: JSX Painter overview displaying                !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function SissifPainterJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locType = paramProps.type;
    /*
    --- Check if it's for Mobile
    */
    if (locCtx.window_o.device === sissifDefDevice.mobile) {
        return (<LocPainterMobileJSX ctx={locCtx} type={locType}/>);
    } else {
        return (<LocPainterDesktopJSX ctx={locCtx} type={locType}/>);
    }
}
