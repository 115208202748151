/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifInit.js                                      !
  !  Desc. : SissiF Front Server initialization                 !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 19/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- Ouestadam products
*/
import {OAComTrans_c} from './oacommon/oaComTrans'
import {sissifDefDevice, sissifDefModal_e, sissifDefOrientation} from "./sissifDef";
import {sissifConfig} from './sissifConfig'
import {sissifTrans} from "./sissifTrans";
import sissifPaintingJson from "./json/sissifPainting.json";

/*=============== Exported functions ==========================*/

/*+-------------------------------------------------------------+
  ! Routine    : sissifInit_f                                   !
  ! Description: Initialize the front server                    !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export async function sissifInit_f(paramCtx) {
    /*
    --- Create Config object
    */
    paramCtx.config_o = sissifConfig;
    /*
    --- Create Translation object
    */
    paramCtx.trans_o = new OAComTrans_c(paramCtx.config_o.locale, sissifTrans);
    /*
    --- Initialize the Refresh functions
    */
    paramCtx.refresh_o = {};
    /*
    --- Current Window information
    */
    paramCtx.window_o = {
        agent: "",
        device: sissifDefDevice.desktop,
        orientation: sissifDefOrientation.landscape,
        width: 0,
        height: 0
    }
    /*
    --- Initialize the current Modal to open
    */
    paramCtx.currentModal = sissifDefModal_e.noModal;
    /*
    --- Read the painting.json file
    */
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname === 'localhost') {
        paramCtx.paintList = sissifPaintingJson;
    } else {
        const locResp = await fetch(paramCtx.config_o.urlPaintings + "/" + paramCtx.config_o.jsonPaintFileName,
            {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
        paramCtx.paintList = await locResp.json();
    }
    /*
    --- Set Not Full Screen
    */
    paramCtx.fullScreen = false;
}
