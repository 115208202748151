/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifConfig.js                                    !
  !  Desc. : SissiF Configuration parameters                    !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 05/03/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Definitions ==================================*/
/*
--- SissiF configuration parameters
*/
export const sissifConfig = {
    version: "2.00",
    modification: "05/03/2024",
    locale: "fr-FR",
    colors: {
        backgroundMainGrey: "#6F7170",
        backgroundMainBlack: "#000000",
        backgroundHeaderStart: "#000000",
        backgroundHeaderEnd: "#9b5f9d",
        backgroundFooterStart: "#9b5f9d",
        backgroundFooterEnd: "#000000",
        backgroundCardStart: "#513252",
        backgroundCardEnd: "#ffffff",
        backgroundIcon: "#6F7170",
        backgroundDialog: "#e2d0e2",
        backgroundDialogStart: "#9b5f9d",
        backgroundDialogEnd: "#000000",
        backgroundCarousel: "#000000",
        foregroundMain: "#ffffff",
        foregroundHeader: "#ffffff",
        foregroundFooter: "#d1e3D0",
        foregroundDialog: "#000000",
        foregroundIcon: "#ffffff",
        foregroundError: "#ff0000",
        foregroundIconButton: "#1976d2"
    },
    urlPaintings: "https://acesteve.fr/paintings",
    jsonPaintFileName: "sissifPainting.json"
};