/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : index.js                                           !
  !  Desc. : SissiF Front server start point                    !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 30/01/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- Diginesfront resources
*/
import './index.css';
import {sissifInit_f} from "./sissifInit";
import {sissifMain_f} from "./sissifMain";

/*=============== Global variables =============================*/
/*
---- General context
*/
const sissifCtx = {};

/*=============== Main =========================================*/
/*
--- Start initialization
*/
sissifInit_f(sissifCtx);
/*
--- Start the Front Main page
*/
sissifMain_f(sissifCtx);
