/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifDef.js                                       !
  !  Desc. : SissiF Definitions file                            !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Exported definitions =========================*/
/*
--- Modals list
*/
export const sissifDefModal_e = {
    noModal: 0,
    largePaintingsModal: 1,
    mediumPaintingsModal: 2,
    smallPaintingsModal: 3,
    setPaintingsModal: 4,
    pastelPaintingsModal: 5,
    painterModal: 6
};

/*
--- Window format
*/
export const sissifDefOrientation = {
    landscape: 0,
    portrait: 1
}

/*
--- Device type
*/
export const sissifDefDevice = {
    desktop: 0,
    tablet: 1,
    mobile: 2
}

/*
--- Paintings type
*/
export const sissifDefPaintType = {
    unknown: 0,
    large: 1,
    medium: 2,
    small: 3,
    set: 4,
    pastel: 5
}
