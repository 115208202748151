/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifConfig.js                                    !
  !  Desc. : SissiF Translation table                           !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 30/01/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Definitions ==================================*/
/*
--- SissiF translation table
*/
export const sissifTrans = {
    "common": {
        "cancel": {
            en: "Cancel",
            fr: "Annuler"
        }, "return": {
            en: "Return",
            fr: "Retour"
        }, "yes": {
            en: "Yes",
            fr: "Oui"
        }, "no": {
            en: "No",
            fr: "Non"
        }, "notAvailable": {
            en: "... Under development ...",
            fr: "... En cours de développement ..."
        }, "extend": {
            en: "Full Screen",
            fr: "Plein écran"
        }, "normal": {
            en: "Normal Screen",
            fr: "Ecran normal"
        }
    },
    "main": {
        "title": {
            en: "Anne-Cécile Estève",
            fr: "Anne-Cécile Estève"
        },
        "subTitle": {
            en: "Oil paintings and pastels",
            fr: "Peintures à l'huile et pastels"
        },
        "cookies": {
            en: "This application doesn't use any Cookie",
            fr: "Cette application n'utilise aucun Cookie"
        }
    },
    "mainLarge": {
        "title": {
            en: "Oil large format",
            fr: "Huile grand format"
        },
        "description": {
            en: "Large format oil paintings",
            fr: "Peinture à l'huile grand format"
        }
    },
    "mainMedium": {
        "title": {
            en: "Oil medium format",
            fr: "Huile moyen format"
        },
        "description": {
            en: "Medium format oil paintings",
            fr: "Peinture à l'huile moyen format"
        }
    },
    "mainSmall": {
        "title": {
            en: "Oil small format",
            fr: "Huile petit format"
        },
        "description": {
            en: "Small format oil paintings",
            fr: "Peinture à l'huile petit format"
        }
    },
    "mainSet": {
        "title": {
            en: "Set of oil paintings",
            fr: "Compositions"
        },
        "description": {
            en: "Set of oil paintings",
            fr: "Peintures à l'huile composés"
        }
    },
    "mainPastel": {
        "title": {
            en: "Pastels",
            fr: "Pastels"
        },
        "description": {
            en: "Pastels on cardboard",
            fr: "Pastels sur carton"
        }
    },
    "mainPainter": {
        "title": {
            en: "Anne-Cécile Estève",
            fr: "Anne-Cécile Estève"
        },
        "description": {
            en: "Overview",
            fr: "Présentation"
        }
    },
    "dialogPainter": {
        "title": {
            en: "Anne-Cécile Estève",
            fr: "Anne-Cécile Estève"
        },
        "text1": {
            en: "Anne-Cécile Estève, born in the 1960s in Aix-en-Provence, discovered oil painting in the workshop of her grandmother Phanette Save Boimond, a graduate of the Beaux Arts in Marseille.",
            fr: "Anne-Cécile Estève, née dans les années 60 à Aix-en-Provence, a découvert la peinture à l'huile dans l'atelier de sa grand-mère Phanette Save Boimond diplômée des Beaux Arts de Marseille.",
        },
        "text2": {
            en: "A childhood made of observations and study of the Provençal countryside and the mountains of Haute-Savoie.",
            fr: "Une enfance faite d'observations et d'étude de la campagne provençale et des montagnes de Haute-Savoie.",
        },
        "text3": {
            en: "Subsequently, it was while living in Vence, a land of flowers, perfumes and lights that Anne-Cécile revealed a predilection for colorful canvases.",
            fr: "Par la suite, c'est en vivant à Vence, pays de fleurs, de parfums et de lumières qu'Anne-Cécile a révélé une prédilection pour les toiles colorées.",
        },
        "text4": {
            en: "Painting allows her to share this atmosphere, these shapes, these colors, which she calls “these pleasures for the eyes” so characteristic of the gardens of the Côte d'Azur.",
            fr: "La peinture lui permet de faire partager cette ambiance, ces formes, ces couleurs, qu'elle nomme «ces plaisirs des yeux» si caractéristiques des jardins de la Côte d'Azur.",
        },
    }
};